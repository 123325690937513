.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.react-confirm-alert {
  min-width: 27rem;
}
.react-confirm-alert-overlay {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  padding-top: 8.1rem;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  opacity: 0;
  -webkit-animation: fadeIn 0.5s 0.2s forwards;
  -moz-animation: fadeIn 0.5s 0.2s forwards;
  -o-animation: fadeIn 0.5s 0.2s forwards;
  animation: fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  position: relative;
  font-family: $font-family-sans-serif;
  width: 27rem;
  padding: 1.87rem;
  text-align: left;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 4px 20px 0 rgba(45,44,49,0.1);
  color: #666;
  // overflow: scroll;
  .btn {
    margin-top: .6rem;
  }
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body .btn-close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
}
.react-confirm-alert-body > h3 {
  font-family: $headings-font-family;
  margin-top: 0;
  font-size: 36px;
  line-height: 1.5;
  text-transform: none;
}


.react-confirm-alert-body p {
  margin-top: 8px;
  color: rgba(45,44,49,0.7);
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 1.87rem;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: $wf-kelly-green;
  border: none;
  display: inline-block;
  padding: 9px 20px;
  color: #fff;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.editAccountManagerModal {
  .form-control.is-invalid {
    padding-right: 2.5rem;
  }
}


///** wf modal **

.wf-modal-body-element {
  //overflow: hidden;
}

.wf-modal-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.wf-modal {
  min-width: 27rem;
  max-width: rem-calc(890);
  top: rem-calc(130);
}
.wf-modal-overlay {
  position: fixed;
  z-index: 10000;
  opacity: 0;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  overflow-y: auto;
  animation: fadeIn 0.5s 0.2s forwards;
}

.wf-modal-body {
  position: relative;
  font-family: $font-family-sans-serif;
  padding: 2.5rem;
  text-align: left;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 4px 20px 0 rgba(45,44,49,0.1);
  color: #666;
  margin: rem-calc(50) 0;

  .btn {
    margin-top: .6rem;
  }

  .input-group-append .btn {
    margin-top: 0;
  }
}

.wf-modal-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.wf-modal-body .btn-close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
}
.wf-modal-body > h3 {
  font-family: $headings-font-family;
  margin-top: 0;
  font-size: 36px;
  line-height: 1.5;
  text-transform: none;
}


.wf-modal-body p {
  margin-top: 8px;
  color: rgba(45,44,49,0.7);
}