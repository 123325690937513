// stylelint-disable scss/dollar-variable-colon-space-after
// Bootstrap overrides

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';

//
// Color system
//

$white: #fff;
$gray-100: #f8f8f8;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #6c6b6e;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #2d2c31;
$gray-900: #151b1e;
$black: #000 !default;

$blue: #20a8d8;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e10808;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #4dbd74;
$teal: #20c997 !default;
$cyan: #63c2de;
$wf-royal-light-blue: #1c34aa;
$wf-royal-blue: #111e60;
$wf-royal-dark-blue: #000c45;
$wf-kelly-green: #70b725;
$wf-kelly-dark-green: #5aa30d;
$wf-charleston: #2d2c31;
$wf-crayola-blue: #1673FF;
$wf-golden-poppy: #f7c500;
$gray-200: lighten($wf-charleston, 65%);

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  wf-royal-light-blue: $wf-royal-light-blue,
  wf-royal-blue: $wf-royal-blue,
  wf-royal-dark-blue: $wf-royal-dark-blue,
  wf-kelly-green: $wf-kelly-green,
  wf-charleston: $wf-charleston,
  wf-crayola-blue: $wf-crayola-blue,
  wf-golden-poppy: $wf-golden-poppy
);

$theme-colors: (
  primary: $wf-royal-blue,
  secondary: $wf-kelly-green,
  success: $wf-kelly-green,
  info: $wf-crayola-blue,
  warning: $wf-golden-poppy,
  danger: $red,
  light: $gray-100,
  dark: $wf-charleston,
  blue: $wf-crayola-blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $wf-golden-poppy,
  green: $wf-kelly-green,
  teal: $teal,
  cyan: $cyan,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: true;
$enable-shadows: true;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-500;

// Links

$link-color: map-get($theme-colors, info);

// Components

$line-height-lg: 1.5;
$line-height-sm: 1;

$border-width: 1px;
$border-color: #ebebeb;

$component-active-bg: map-get($theme-colors, info);

// Fonts

$font-family-sans-serif: "Open Sans", Lato, Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem;
$font-size-larger: 1.12rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: $font-size-base;

$font-letter-spacing: -0.00625em;

$font-weight-bold: 600;
$font-weight-extra-bold: 800;

$line-height-base: 1.5;

$h1-font-size: $font-size-base * 3.125; // 50px
$h2-font-size: $font-size-base * 2.25;  // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.375; // 22px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base;         // 16px

$headings-font-family: "Khula", Lato, Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 800;
$headings-color: $gray-800;
$headings-letter-spacing: $font-letter-spacing;

$small-font-size: 90%;

$text-muted: $gray-300;

// Breadcrumbs

$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

// Pagination

$pagination-padding-y: .35rem;

$pagination-hover-bg: lighten($component-active-bg, 40%);

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: lighten($wf-charleston, 65%);
$dropdown-divider-bg: $gray-100;

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $body-color;

$dropdown-item-padding-y: .875rem;
$dropdown-item-padding-x: .906rem;

// Buttons

$btn-font-weight: 800;
$btn-secondary-border: $gray-300;
$btn-link-color: map-get($theme-colors, success);
$btn-link-hover-color: darken($btn-link-color, 15%);
$btn-box-shadow: none;

// Progress bars

$progress-bg: $gray-100;

// Buttons + Forms

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1.25rem;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0;

$input-btn-padding-y-sm: 0.3rem;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.6rem;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Tables

$table-accent-bg: $gray-100;
$table-hover-bg: rgba($gray-200, 0.2);

$table-border-color: $border-color;

// Forms

$label-margin-bottom: 0.43rem;

$input-padding-y: 0.75rem;
$input-padding-x: 0.875rem;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: 0.5625rem;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-border-color: lighten($wf-charleston, 65%);
$input-border-width: $input-btn-border-width;
$input-box-shadow: none;

$input-focus-border-color: $component-active-bg;

$input-height-inner: ($font-size-base * $input-line-height) + ($input-padding-y * 2);

$input-height-inner-sm: ($font-size-sm * $input-line-height-sm) + ($input-padding-y-sm * 2);

$input-height-inner-lg: ($font-size-lg * $input-line-height-lg) + ($input-padding-y-lg * 2);

$form-group-margin-bottom: 1.3rem;

$input-group-addon-bg: $gray-100;
$input-group-addon-border-color: $gray-200;

// $custom-control-gutter: 1.9rem;

$custom-control-indicator-size: 1.3rem;
$custom-control-indicator-bg: none;
$custom-control-indicator-box-shadow: none;

$custom-control-indicator-border-color: $input-border-color;

$custom-control-indicator-checked-bg: map-get($theme-colors, success);

$custom-control-indicator-focus-box-shadow: $custom-control-indicator-box-shadow;
$custom-control-indicator-focus-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-active-color: $custom-control-indicator-checked-bg;
$custom-control-indicator-active-bg: lighten($custom-control-indicator-checked-bg, 35%);

// $custom-checkbox-indicator-indeterminate-bg: #fff;
$custom-checkbox-indicator-indeterminate-bg: $custom-control-indicator-checked-bg;

$custom-select-indicator-padding: 0.2rem; // Extra padding to account for the presence of the background-image based indicator

$custom-select-box-shadow: none;

$custom-select-font-size-sm: 100%;

// Form validation

$form-feedback-valid-color: map-get($theme-colors, success);
$form-feedback-valid-focus-color: lighten($form-feedback-valid-color, 12.5%);
$form-feedback-invalid-color: map-get($theme-colors, danger);
$form-feedback-invalid-focus-color: lighten($form-feedback-invalid-color, 15%);

// Badges

$badge-font-size: 100%;
$badge-font-size-rem: $font-size-base * ($badge-font-size / 100%);
$badge-padding-y: .125em;
$badge-padding-x: .562em;

// Modals

$modal-inner-padding: 0 2rem 2rem;

$modal-dialog-margin-y-sm-up: 9rem;

$modal-content-border-color: transparent;
$modal-content-box-shadow-xs: 0 4px 30px 0 lighten($gray-200, 10%);
$modal-content-box-shadow-sm-up: $modal-content-box-shadow-xs;

$modal-backdrop-bg: $white;
$modal-backdrop-opacity: .7;
$modal-header-border-color: transparent;
$modal-header-padding: 2rem;

$modal-md: 500px;

// Alerts
$alert-bg-level: -12;
$alert-border-level: 0;
$alert-color-level: 0;

// Close

$close-font-size: $font-size-base * 2.5;
$close-font-weight: 600;

// stylelint-enable


$container-large-desktop:	(1180px + 30px);

$grid-gutter-width: 30px;