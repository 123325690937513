.sidebar {
  .nav-title {
    font-size: $sidebar-nav-title-font-size;
  }

  .nav {
    min-height: auto;
  }

  .nav-link {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 0.91em;
    &.active {
      background: #123387;
    }
    &.nav-dropdown-toggle.active {
      background: none;
    }
  }

  .sidebar-minimized & {
    .nav-link i {
      color: $sidebar-nav-link-color;
      min-height: 1px;
    }
  }
}

.sidebar-minimized .sidebar .nav {
  .nav-dropdown-items {
    display: none;
  }
}

.sidebar-minimized .sidebar .nav-link {
  height: 52px;
}

.sidebar-minimized .sidebar .nav-link i {
  margin-right: 0.93rem;
  font-size: 14px;
  min-height: 40px;
}
.sidebar-minimized .sidebar .nav-link img {
  margin-left: 0.93rem;
  width: 1.6875rem;
}
.sidebar-minimized .sidebar .nav-link:hover {
  width: 50px;
}
.sidebar-minimized .sidebar .nav-link span {
  display: none;
}
.sidebar-minimized .sidebar .nav .nav-dropdown-items .nav-item a {
  padding-left: 0.93rem;
}

.sidebar .nav-link i {
  padding: 7px 0;
}
.sidebar .nav-link i,
.sidebar .navbar .dropdown-toggle i,
.navbar .sidebar .dropdown-toggle i {
  color: rgba(255, 255, 255, 0.7);
}

.sidebar .nav-link span {
  position: relative;
  top: 2px;
}

.sidebar .nav-dropdown-toggle::before {
  height: 10px;
  width: 10px;
}


ul.nav-dropdown-items a.nav-link {
  padding-left: 0;
}

.sidebar-lg-show ul.nav-dropdown-items a.nav-link {
  padding-left: 43px;
}