.table {
  border: 1px solid $border-color;

  > thead,
  > tfoot {
    > tr > th {
      font-size: 0.875rem;
      font-weight: 600;

      &.sorting {
        &::before,
        &::after {
          font-family: FontAwesome;
          right: 0.5em !important;
        }

        &::before {
          content: '\F0DE' !important;
        }

        &::after {
          content: '\F0DD' !important;
        }
      }
    }
  }

  thead {
    th {
      border-bottom: 1px solid transparent;
      font-size: 15px;
      color: $gray-800;
      font-weight: 400;
      height: 50px;
      outline: 0;
    }

    th[data-row-selection='true'] {
      width: 3.1rem;

      .custom-checkbox {
        top: 0.31rem;
      }
    }
  }

  > tbody {
    font-size: 0.9375rem;

    tr {
      .table-action-btn {
        padding: 0 10px;
        font-size: 18px;
        text-decoration: none;
        display: flex;

        &--delete {
          color: $red;
        }
      }

      &:last-of-type {
        td {
          border-bottom: 1px solid #ebebeb;
        }
      }
    }

    td {
      color: $gray-800;
      font-size: 15px;
      word-break: break-word;
      padding: 0.35rem 0.7rem;
      vertical-align: middle;
      border-bottom: 1px solid transparent;
      height: 2.8rem;

      .custom-checkbox {
        top: 0.25rem;
      }
    }
  }
}


.table-hover tbody tr:hover {
  background-color: transparent !important;
}

.table-striped tbody tr:hover:nth-of-type(odd) {
  background-color: #f8f8f8 !important;
}

.react-bootstrap-table-pagination-total {
  color: $text-muted;
  font-size: 13px;
}

ul.pagination {
  margin-bottom: 0;

  li.page-item {
    margin-right: 0.625rem;

    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &[title='next page'] a,
    &[title='previous page'] a {
      color: #1673ff;
      background-color: transparent;

      &:hover {
        color: lighten(theme-color('primary'), 0.4) !important;
      }
    }

    a {
      border: 0;
      background-color: rgba(22, 115, 255, 0.4);
      font-size: 0.8125em;
      color: #fff;
    }
  }

  li.page-item a {
    border-radius: 0.25rem;
  }

  li.active a {
    border-radius: 0.25rem;
    background-color: theme-color('primary');
  }
}

.table-actions {
  margin-bottom: 1.25rem;

  span {
    cursor: pointer;
  }
}

.carets {
  margin-top: 2px;
  display: inline-flex;
  flex-direction: column;
  float: right;

  span {
    font-size: 1.3em;
    line-height: 0.5;
    color: rgba(45, 44, 49, 0.2);
  }
}

.custom-table-actions {
  display: flex;
  align-items: flex-end;

  span {
    margin-right: 1rem;
  }
}

.pricing-rule-table {
  td {
    .form-group {
      margin-bottom: 0 !important;
    }
  }

  input[type="text"] {
    height: 37px;
  }

  .input-group-append {
    height: 37px !important;

  }

  // #notproud
  .input-group-text {
    height: 37px !important;
    .input-group-text {
      height: 37px !important;
    }
  }
  .input-group-prepend {
    .input-group-text {
      height: 37px !important;
    }
  }



  .pricing-rule-input {
    width: 85px;
    float: left;
    margin-right: 15px;
  }

  &.custom-pricing-rule-table td:last-of-type {
    cursor: pointer;
  }
  &.custom-pricing-rule-table td:first-of-type {
    cursor: default; //Ensure that if there is only 1 cell it doesn't have the pointer.
  }
}

.pricing-rule-table {
  .invalid-feedback {
    display: none !important;
  }
}

.field-array-table {
  td {
    .form-group {
      margin-bottom: 0 !important;
    }
  }
}

table.table-expand {
  width: auto;
  padding-right: 30px;
  td {
    min-width: 175px;
    vertical-align: top;
  }
}

.table.table-striped-inverse tbody tr:nth-of-type(2n) {
  background-color: $table-accent-bg;
}

.table-teamTargets {
  td {
    border-left: #EBEBEB 1px solid;
  }
  tr:nth-of-type(1) {
    td {
      padding: 16px 15px 16px 15px;
    }
  }
  tr:nth-of-type(2) {
    td {
      padding: 5px 14px;

      .form-group {
        margin-bottom: 0;

        input[type=text] {
          line-height: 16px;
          height: 32px;
          font-size: 15px;
        }
      }

      .input-group-text {
        padding: 6px 12px 7px 13px;
        width: 35px;
      }

      .wf-icon-gbp {
        font-size: 16px;
        left: -6px;
        top: 1px;
      }
    }

    td:first-of-type {
      padding: 13px 15px 0 15px;
    }
  }
  tr:nth-of-type(3) {
    td {
      font-size: 15px;
      padding: 13px 15px 13px 15px;
      line-height: 16px;
      strong {
        font-weight: 600;
      }
    }
  }
}

.es-policy-table table {
  .policy-approval-success, .policy-approval-success:hover {
    background-color: #d1e7dd !important;
  }
  .policy-approval-error, .policy-approval-error:hover, .policy-approval-error:hover:nth-of-type(2n+1) {
    background-color: #f8d7da !important;
  }
}
