// Here you can add other styles

@import 'custom-variables';
@import 'aside';
@import 'badges';
@import 'breadcrumb';
@import 'buttons';
@import 'dropdown';
@import 'forms';
@import 'helpers';
@import 'modals';
@import 'notifications';
@import 'omnibox';
@import 'popovers';
@import 'select2';
@import 'tables';
@import 'typography';
@import 'tooltips';
@import 'sidebar';
@import 'kanban-board';
@import 'avatar';
@import 'payment-card';

.list-group--permissions {
  border: 1px solid #ebebeb !important;
  margin-bottom: rem-calc(20);
  label {
    cursor: pointer;
  }

  .custom-control-label {
    margin-top: 2px;
  }

  .list-group-item {
    color: #2d2c31;
    cursor: pointer;
    font-size: 15px;
    border: none !important;
    margin-bottom: 0;

    &:hover,
    &:active {
      background-color: transparent;
    }

    padding: 0.6rem 0.85rem;
    border-radius: 0 !important;

    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

}

li {
  list-style: none;
}

.list-info {
  padding-left: rem-calc(16);

  li {
    &:before {
      content: "\2022";
      color: theme-color('warning');
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  &:before {
    background: white;
  }
}

.custom-radio .custom-control-label::before {
  top: 1px;
  left: -29px;
}

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
}

.edit-dealer-column {
  max-width: 645px;
}

.edit-dealer-invoicing {
  margin-left: auto;
}

@media(max-width: 960px) {
  .edit-dealer-invoicing {
    margin-left: 0;
  }
  .edit-dealer-container {
    flex-direction: column;
    .edit-dealer-column {
      width: 100%;
      max-width: none !important;
      > div {
        width: 100%;
      }

      border-right: 0 !important;
    }
  }
}

.pull-up {
  margin-top: -16px;
  margin-bottom: 20px;
}

.select-z-index-fix > div:nth-of-type(2) {
  /** Avoids the dropdown overlapping other elements below **/
  z-index: 9999;
}

.pricing-rule-input .react-select-container {
  .react-select__value-container {
    padding: 0 8px;

    & > div:nth-child(2) {
      height: 30px;
    }
  }
}

.table-individualTargets {
  tfoot td {
    font-weight: 600;
    color: #000;
    font-size: 14px;
  }
  .user-avatar {
    display: inline-block;
    position: relative;
    top: 9px;
  }

  td:first-of-type {
    min-width: 250px;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.form-control-sm ~ .input-group-append {
  height: calc(1em + 1.125rem + 3px);
}

.table-individualCommission {
  .form-group {
    margin-bottom: 0;
  }
  .if {
    display: inline-block;
    width: 25px;
  }
  .field {
    display: inline-block;
    width: calc(100% - 25px);
  }
}

.create-policy-hint {
  padding-left: 30px;

  p {
    margin-top: 0;
  }
}

.create-policy-checkbox {
  height: 28px;
}

.quote-options {
  .react-select-container {
    width: calc(100% - 50px);
  }

  .react-select__control {
    width: 100%;
  }

  #sundries.react-select-container {
    width: 100%;
  }
}

.policy-edit-form {
  .input-group-prepend ~ .react-select-container, #excess {
    width: calc(100% - 50px);
  }

  .react-select__control {
    width: 100%;
  }
}

.billing-summary {

  max-width: 1200px;

  .amount-coming-due {
    font-weight: bold;

    div:nth-of-type(1) , div:nth-of-type(2) {
      border-top: 1px solid black;
    }
  }

  .amount-overdue {
    font-weight: bold;
    color: red;
  }
}

.sagepay-logo {
  height: 100px;
  background: url('../images/payments-by-sagepay.png') no-repeat;
}

.direct-debit-mandate {
  max-width: 750px;
  margin: auto auto;

  .sun {
    letter-spacing: 0.5rem;
  }
  .instruction {
    font-size: 0.8rem;
  }
  .direct-debit-guarantee {
    font-size: 0.8rem;
    padding-left: 20px;
    li {
      list-style: initial;
    }
  }
  .mandate-error {
    color: red;
    font-size: 10pt;
    border: 1px solid red;
    border-radius: 5px;
    background: #ffeeee;
    padding: 10px 15px;
  }
  .btn-primary {
    width: 200px;
  }
}

.direct-debit-logo {
  height: 50px;
}
