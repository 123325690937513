.avatar-container {
  background-color: #2D2C31;
  width: 10.75rem;
  height: 10.75rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .no-avi {
    color: #FFF;
    font-size: 4.75em;
    font-weight: 800;
  }
}