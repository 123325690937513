.btn {
  font-family: $font-family-sans-serif;
  i {
    font-size: 0.8em;
    margin-right: 0.3em;
  }
  &:active {
      box-shadow: none !important;
  }
  &-warning {
    padding: 0.5rem 0.625rem;
    font-size: 0.875em;
    font-weight: 700;

    &:hover {
      background-color: #E6A600 !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value, darken($value, 8.5%), darken($value, 8.5%), darken($value, 10%), darken($value, 10%));
  }
}

.btn-link {
  color: #1673FF;
  text-decoration: underline;

  @include hover {
    color: #1673FF;
  }
}

.btn-input-height {
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
}

.btn-input-height-sm {
  height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  line-height: $input-line-height-sm;
}

.btn-form-cancel {
  color: $wf-kelly-green;
}

.btn-delete-resource {
 width: 3.5rem;
 height: 2.5rem;
 text-align: center;
 border: 0;
 border-radius: 0.25rem;
 color: $red;
 background-color: #F8F8F8;
 outline: 0 !important;
 transition: background .35s ease-in-out;

 &:hover {
   background-color: darken(#F8F8F8, 3%);
 }
}

.btn-warning {
  color: $gray-800;
}

.btn-large {
  font-size: 1.1em;
  font-weight: 800;
}

.btn-action {
  background-color: #F8F8F8;
  border-radius: 0.25rem;
  color: #2D2C31;
  text-align: center;
  height: 35px;
  font-size: inherit;

  i {
    margin-right: 0;
    font-size: inherit;
  }
}

.btn-secondary {
  &:hover {
    background-color: #5AA30D !important;
  }
}

.btn-gray {
  @extend .btn, .btn-transparent, .btn-action, .btn-sm, .font-lg, .font-weight-bold;
}

button[class*=' btn-outline-'],button[class^='btn-outline-'] {
  border-width: 2px;
}

.garage-info {
  float: right;
  cursor: pointer;
  position: relative;
  top: 4px;
}