.payment-card {
    label {
        margin-top: 20px;
    }
    input[name=card_expiry_month], input[name=card_expiry_year] {
        display: inline-block;
    }
    input[name=card_expiry_month], input[name=card_expiry_year] {
        max-width: 70px;
    }
    input[name=card_expiry_month] {
        margin-right: 5px;
    }
    input[name=card_expiry_year] {
        margin-left: 5px;
    }
    input[name=card_cvv] {
        max-width: 70px;
    }
    .card-error {
        color: red;
        font-size: 10pt;
        border: 1px solid red;
        border-radius: 5px;
        background: #ffeeee;
        padding: 10px 15px;
    }
}
