.select2-container--bootstrap4 {
  .select2-selection {
    @extend .form-control;
    padding: 0;
  }

  &.select2-container--focus,
  &.select2-container--open {
    .select2-selection {
      border-color: $input-focus-border-color;

      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
      } @else {
        box-shadow: $input-focus-box-shadow;
      }

      select.is-invalid + & {
        border-color: $form-feedback-invalid-focus-color;
        @if $enable-shadows {
          box-shadow: $input-box-shadow, 0 0 0 $input-focus-width rgba($form-feedback-invalid-focus-color, .25);
        } @else {
          box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-focus-color, .25);
        }
      }

      select.is-valid + & {
        border-color: $form-feedback-valid-focus-color;
        @if $enable-shadows {
          box-shadow: $input-box-shadow, 0 0 0 $input-focus-width rgba($form-feedback-valid-focus-color, .25);
        } @else {
          box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-focus-color, .25);
        }
      }
    }
  }

  select.is-invalid + & .select2-selection {
    @extend .form-control, .is-invalid;
  }

  select.is-valid + & .select2-selection {
    @extend .form-control, .is-valid;
  }

  select.is-invalid + & .select2-selection,
  select.is-valid + & .select2-selection {
    background: none;
  }

  .select2-selection--multiple {
    &::after {
      @extend %selection-arrow;
      @extend .icon-arrow-down, :before;
    }

    .select2-selection__rendered {
      line-height: $input-line-height;
      padding: $input-padding-y $input-padding-y;
    }

    .select2-selection__choice {
      background: $gray-100;
      border-color: $input-border-color;
      margin: (-($input-padding-y - 0.45rem)) 0 (-($input-padding-y - 0.55rem));
      padding: ($input-padding-y - 0.5rem) 1.8em ($input-padding-y - 0.5rem) 0.5em;
      position: relative;

      &:first-child {
        left: -($input-padding-x - 0.5rem);
      }
    }

    .select2-selection__choice__remove {
      @extend .remove;
    }

    .select2-search--inline {
      .select2-search__field {
        color: $input-color;
        padding: 0;

        &::placeholder {
          color: $input-placeholder-color;
          // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
          opacity: 1;
        }
      }
    }
  }

  .select2-selection--single {
    padding: $input-padding-y $input-padding-x;

    .select2-selection__arrow {
      @extend %selection-arrow;

      b {
        display: none;
      }
    }
  }

  .select2-results__option {
    padding-left: $input-padding-x;

    &--highlighted[aria-selected] {
      background: $gray-100;
      border-radius: $border-radius;
      color: $body-color;
    }
  }

  .select2-dropdown {
    border-color: $input-focus-border-color;
    overflow-x: visible;
  }
}

%selection-arrow {
  @extend .icon-arrow-down;
  line-height: $input-line-height;
  margin-top: 0.15em;
  position: absolute;
  right: $input-padding-x;
  top: $input-padding-y;
  width: auto;
}

.prepended-select {
  flex: 1;
}