[data-id='tooltip'] {
  font-size: 13px;
  padding: 0.25rem 0.53rem;
  border-radius: 0.25rem;
}
.__react_component_tooltip {
  font-size: 13px;
  font-weight: 500;
}

.custom-tooltip {
  .tooltip-inner {
    font-size: 13px !important;
    font-weight: 500;
    line-height: 16px;
  }
}

.tooltip-nobreak {
  word-break: keep-all;
}