.input-group-prepend,
.input-group-append {
  vertical-align: middle; // Match the inputs
  white-space: nowrap;
}

.input-group-price {
  width: 80px;
  height: 32px;
  background: #fff;
}

.price-band-table {
  display: inline-table;
  table-layout: fixed;
  thead {
    tr th {
      width: 160px;
    }
  }
  tbody {
    td {
      .form-group {
        margin-bottom: 0;
      }
    }
  }

  .input-group {
    background: #fff;
  }

  .input-group-prepend {
    width: 38px;
    .input-group-text {
      width: 38px;
      text-align: center;
      padding-left: 8px;
    }
  }
}

.price-band-table thead tr th.price-band-table__th-special {
  width: 250px;
}