.nav-tabs {
  li {
    &:first-of-type {
      .nav-link {
        //padding-left: 0;
      }
    }
  }
  .nav-link {
    color: $gray-600;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    &:hover {
      cursor: pointer;
      border-bottom-color: transparent;
    }

    &.active {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      //border-bottom-color: #fff;
      //border-color: $border-color;
      color: $gray-800;

      &:focus {
        background: #fff;
        //border-bottom-color: #fff;
        //border-color: $border-color;
      }
    }
  }
}

.tab-content {
  background: #fff;
  margin-top: -1px;
  border-bottom: 1px solid $border-color;

  .tab-pane {
    padding: $spacer;
    border-top: 1px solid $border-color;
  }

}

.card-block {
  .tab-content {
    border: 0;
    margin-top: 0;
  }
}

.top-nav {
  .dropdown-menu {
    width: 11.25rem;
    .dropdown-item {
      span {
        font-size: 1em;
      }
    }
  }

  .nav-link-icon {
    margin-right: 1.25rem;
    font-size: 1.3em;
    text-decoration: none;
    margin-top: 0.31rem;
    color: #2d2c31;

    &:hover {
      text-decoration: none;
    }

    &.active {
      color: $wf-crayola-blue;
    }

    .icon-bell {
      position: relative;
      top: 2px;
    }

    .notification-dot {
      height: 8px;
      width: 8px;
      background-color: red;
      border-radius: 50%;
      border: 1px solid white;
      display: inline-block;
      position: relative;
      top: -10px;
      left: -10px;

      &.hidden {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .user-dropdown-menu {
    span.user-avatar {
      border: 2px solid transparent;
    }
    &.show {
      span.user-avatar {
        border: 2px solid #1673ff;
      }
    }
  }
}

.app-header.navbar .dropdown-menu {
  top: 45px;
  border: 1px solid rgba(45, 44, 49, 0.2);
  border-top: 1px solid #ebebeb;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(45, 44, 49, 0.1);

  .dropdown-item {
    color: rgba(45, 44, 49, 0.7);
    font-family: $font-family-sans-serif;
    font-size: 1em;
    padding: 0.625em 1em;

    &:active {
      color: #fff;
    }
  }
}

.user-avatar {
  cursor: pointer;
  width: 2.6rem;
  height: 2.6rem;
  display: block;
  border-radius: 1.3rem;
  background-color: #2d2c31;
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  font-family: $headings-font-family;
  border-radius: 14px;
  text-align: center;
  line-height: 2.6rem;
  letter-spacing: -1px;
  overflow: hidden;

  &--small {
    width: 28px;
    height: 28px;
    font-size: 10px;
    text-align: center;
    line-height: 30px;
    border-radius: 16px;

    [data-id='tooltip'] {
      font-family: $font-family-sans-serif;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  &--xsmall {
    width: 24px;
    height: 24px;
    font-size: 8px;
    text-align: center;
    line-height: 28px;
    border-radius: 12px;
  }

  &--medium {
    width: 40px;
    height: 40px;
    font-size: 13px;
    text-align: center;
    line-height: 40px;
    border-radius: 20px;
    border: 2px solid;
  }
}
