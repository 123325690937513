.dashboard-tile {
  display: flex;
  align-items: center;
  background: white;

  h6 {
    color: rgba(45,44,49,0.7);
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  span {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba(45,44,49,0.7);
  }
}

.dashboard-date-select {
  button[type=submit] {
    padding: 10px 10px 10px 10px;
  }
  .date-label {
    margin: 0 10px 6px 10px;
  }
  .to-date-field {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  .form-group:last-of-type {
    margin-right: 5px;
    label {
      label {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .b-form-group.form-group > div {
    width: 120px;
  }
}

.main .container-fluid.dashboard-grey-extend {
  padding-bottom: 20px;
}

.dashboard-tile-icon {
  width: 4.3rem;
  height: 4.3rem;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.blue {
    background: #321EDB;
  }

  &.green {
    background: #05B100;
  }

  &.red {
    background: #DA2800;
  }

  &.grey {
    background: #f8f8f9;
  }

}

.dashboard-tile {
  .cropped {
    width: 82%;

    span {
      font-size: 0.9rem;
      font-weight: normal;
      // line-height: 1.2;
    }
  }
}

.dashboard-segment {

  background-color: white;
  padding: 20px 15px;
  border-left-style: solid;
  border-left-width: 4px;

  &.blue {
    border-left-color: #321EDB;
  }

  &.green {
    border-left-color: #05B100;
  }

  &.red {
    border-left-color: #DA2800;
  }

  .header {
    margin: 10px 0 30px 0;
  }

  h4 {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 700;
  }

  table thead tr th {
    border-bottom: 1px solid #ebebeb;
    font-weight: 700;
    vertical-align: middle;
  };

}

.full-h {
  height: 100vh;
}

.table-spacing {
  margin-top: 40px;
  padding: 28px 20px;
}

.prospect-source-tile {
  position: relative;
  background: #fff;
  padding: 40px 25px;
  svg {
    width: 38px;
    position: absolute;
    top: 38px;
    right: 20px;
    path {
      fill: #99A0AD;
    }
  }
}

.progress-bar {
  position: relative;
  height: 4px;
  width: 100%;
  background: #aaa;

  .progress {
    background: #F7C500;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress-red {
    background: #DA2800;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress-green {
    background: #05B100;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
}