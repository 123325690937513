body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.font-xs {
  font-size: 0.75rem !important; // 12px
}

.font-sm {
  font-size: 0.875rem !important; // 14px
}

.font-md {
  font-size: 0.9375rem !important;
}

.font-lg {
  font-size: 1rem !important; // 16px
}

.font-xl {
  font-size: 1.25rem !important; // 20px
}

.font-2xl {
  font-size: 1.5rem !important; // 24px
}

.font-3xl {
  font-size: 1.75rem !important;
}

.font-4xl {
  font-size: 2rem !important;
}

.font-5xl {
  font-size: 2.5rem !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 600 !important;
  color: $gray-800;
}

.font-weight-bold {
  font-weight: 800 !important;
  color: $gray-800;
}

.ph-claim {
  margin-bottom: 1.875rem;
}

.ph-claim-price {
  span {
    color: green;
  }
}

.label {
  font-size: 0.875rem; // 14px
  font-weight: 600;
  color: rgb(45, 44, 49);
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.text-gray {
  color: gray;
}
.text-lightgray {
  color: lightgray;
}