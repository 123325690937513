.vehicle-type-radio {
  input[type="radio"] {
    visibility: hidden;
    display: none;
  }

  &-label {
    border: 1px solid #eee;
    padding: 20px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .35s ease-in-out;
  }
}

.vehicle-type-radio.selected {
  .vehicle-type-radio-label {
    border: 1px solid #70B725;
    background-color: rgba(112,183,37,0.05);
  }
}