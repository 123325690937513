.timeline-page {
  height: 100%;
}

.timeline-page-container {
  position: relative;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.timeline-header {
  padding: 2.5rem 1.875rem;
}

.timeline-page-content {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(xl) {
    flex-wrap: nowrap;
  }

  > div {
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }
}


.ph-detail {
  margin-bottom: 2.5rem;
}

.ph-detail-actions, .quote-detail-actions {
  display: flex;
  justify-content: flex-start;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
}

.ph-detail-actions button, .quote-detail-actions button {
  margin-right: 1.25rem;

  &:last-of-type {
    margin-right: 0;
  }
}

.ph-sidebar-card {
  padding: 1.875rem;

  .card-header {
    background: none;
    border: none;
    padding: 0;
  }

  .ph-claim-date {
    font-size: 0.86em;
  }
}

.ph-details {
  background: #fff;
  border-radius: 4px;

  flex-wrap: wrap;

  @include media-breakpoint-up(lg) {
    flex-wrap: none;
    max-width: 910px;
  }

  .card .card-body {
    padding: 1.875rem 0.9375rem; // 30px 15px
  }
}

.ph-details-person {
  min-width: 270px;
  padding: 1.875rem 1.875rem 0;
  flex: 1;
  @include media-breakpoint-up(md) {
    border-right: 1px solid #eee;
    padding: 1.875rem;
  }
}

.ph-details-policies {
  padding: 1.875rem;
}

.ph-details-items {
  display: flex;
  flex-direction: column;
}

.ph-details__row {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }
}

.ph-details__column {
  flex-direction: column;
  min-width: 150px;
  @include media-breakpoint-up(sm) {
    min-width: 170px;
  }
}

.wf-timeline-sidebar {
  position: relative;
  display: flex;
  margin-top: 1.875rem;
  flex-wrap: wrap;
  flex: 1;

  @include media-breakpoint-up(xl) {
    margin-top: 0;
    margin-left: 1.875rem;
    max-width: 270px;
    flex-direction: column;
    align-self: flex-start;
    flex: 0 0 100%;
  }

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    flex: 1;
  }
}

.wf-timeline-card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 1.875rem;
  margin-bottom: 1.875rem;
  width: 100%;
  flex-basis: 100%;

  h6 {
    margin-bottom: 1.25rem;
  }

  @include media-breakpoint-up(md) {
    margin-right: 1.875rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/**
 * Timeline
*/

.wf-timeline {
  margin-top: 1.875rem; // 30px

  header {
    padding: 1.875rem;
  }
}

.timeline-card {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid rgba(45, 44, 49, 0.1);
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
  }

  &--pinned {
    background-color: rgba(247, 197, 0, 0.07);
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.875rem;
    padding-right: 1.875rem;

    .timeline-card-actions {
      button.btn-action {
        background-color: rgba(247, 197, 0, 0.1);
      }
    }
  }

  &-user {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.timeline-card-actions {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    margin-top: 1.5rem;
    flex-direction: row;
  }
}

.timeline-card-content {
  max-width: 590px;
  width: 100%;
  padding-right: 38px;
  position: relative;
}

.timeline-card-meta {
  border-top: 1px solid rgba(45, 44, 49, 0.1);
  padding: calc(1.875rem / 2);
  margin-top: 1rem;

  @include media-breakpoint-up(sm) {
    border-top: 0;
    margin-top: 0;
    border-left: 1px solid rgba(45, 44, 49, 0.1);
  }
}

.timeline-card-pinned {
  border: 1px solid #f7c500;
  border-radius: 19px;
  background-color: rgba(247, 197, 0, 0.1);
}

.card-view-more {
  border-radius: 0 0 4px 4px;
  background-color: rgba(22, 115, 255, 0.05);
  padding: 1.158rem 0;
  color: #1673ff;
  font-size: 14px;
  text-align: center;
  margin-bottom: -1.875rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  cursor: pointer;
}

.unpin {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  cursor: pointer;
}