body {
  letter-spacing: $font-letter-spacing;
}

h1, h2,
.h1, .h2 {
  letter-spacing: $headings-letter-spacing
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  font-family: $font-family-sans-serif;
  letter-spacing: $font-letter-spacing;
}

h5 {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: none !important;
}

h6 {
  font-size: 0.875em;
  font-weight: 600;
}

.lh-1 {
  line-height: 1 !important;
}

.heading-font {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  letter-spacing: $headings-letter-spacing;
}

.text-regularcase {
  text-transform: none !important;
}

small {
  font-size: 0.8em;
  color: rgba(45,44,49,0.7);
}

.page-title {
  font-size: 2.25em;
}
.title-xs {
  font-size: 0.87em;
  font-weight: 500;
}

.text-italic-loud {
  font-family: 'Open Sans Italic', Arial, Helvetica, sans-serif;
  font-style: italic;
  color: rgba(45,44,49,0.7);
  font-size: 14px;
  text-transform: uppercase;
}