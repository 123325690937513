.dropdown-item {
  @extend %reset-core-ui-dropdown;
}

h6.dropdown-header {
  @extend %reset-core-ui-dropdown;
  @extend .small;
  font-weight: $font-weight-bold;
}

%reset-core-ui-dropdown {
  border-bottom: none;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;

  i {
    @extend .m-0;
    color: inherit;
    width: auto;
  }
}

.size-per-page {
  margin-bottom: 1.25rem;
  span {
    font-size: 0.93em;
  }
}
.size-per-page-dropdown {
  .dropdown-toggle {
    border-radius: 0.25rem;
    height: 2.25rem;
    border: 1px solid rgba(45, 44, 49, 0.2);
    border: 1px solid #eee;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.93rem;
    padding-right: 0.93rem;
  }

  .dropdown-item {
    padding: 0.875rem;
    font-size: 0.875em;
    color: rgba(45, 44, 49, 0.7);
  }
}

.dropdown-menu {
  min-width: 6rem;
}

.size-per-page-dropdown .dropdown-toggle {
  font-size: 0.875em;
  font-weight: 400;
}

.user-dropdown.active .user-avatar {
  border: 2px solid #1673ff;
}

.color-picker {
  width: rem-calc(50);
  height: rem-calc(50);
  border: 1px solid rgba(45, 44, 49, 0.2);
  border-radius: rem-calc(4);
  display: block;
  position: relative;
  z-index: 1;

  .swatch {
    display: flex;
    width: rem-calc(30);
    height: rem-calc(30);
    margin-right: rem-calc(10);
    margin-bottom: rem-calc(10);
    cursor: pointer;

    &--default {
      margin-top: rem-calc(10);
      margin-left: rem-calc(10);
    }
  }

  .color-picker-dropdown {
    box-shadow: border-box;
    width: rem-calc(262);
    position: absolute;
    padding: rem-calc(15);
    display: flex;
    flex-wrap: wrap;
    top: rem-calc(60);
    left: 0;
    border: 1px solid rgba(45, 44, 49, 0.2);
    box-shadow: 0 4px 20px 0 rgba(45, 44, 49, 0.1);
    background: #fff;

    .swatch:nth-child(6n) {
      margin-right: 0;
    }
  }
}
