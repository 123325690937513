.kanban-board {
  display: flex;
  height: 81vh;
  overflow-x: scroll;

  @media (max-width: 1024px) {
    height: calc(77vh - 30px);
  }

  .board-header {
    height: 40px;
    background: #f7f7f8;
    position: sticky;
    top: 0;
    width: 18.7rem;
    z-index: 0;
    margin-left: -10px;
    padding: 11px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    h3 {
      color: #2d2c31;
      font-size: 0.875rem;
      text-transform: none;
      font-weight: 600;
      letter-spacing: -0.1px;
      line-height: 1em;
    }
  }

  .board-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 18.75rem;
    border: 1px solid rgba(45, 44, 49, 0.1);
    border-radius: 4px;
    background-color: rgba(45, 44, 49, 0.03);
    margin-right: 1.25rem;
    padding: 0 0.625rem 0.625rem;
    flex: 0 0 auto;
  }

  .board {
    position: relative;
    height:100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .board-items {
    margin-top: 10px;
    height: 100%;
  }

  .card {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(45, 44, 49, 0.1);
    padding: 0.75rem;
    border: 0;
    margin-bottom: 0.625rem;
    cursor: pointer;

    &:hover {
      .header__actions {
        color: map-get($theme-colors, blue);
      }
    }

    header {
      h3 {
        color: #2d2c31;
        font-size: 1em;
        text-transform: none;
        font-weight: 600;
        letter-spacing: -0.1px;
        line-height: 1em;
        margin-bottom: 0;
      }

      div.text-muted {
        color: rgba(45, 44, 49, 0.7);
        font-size: 1em;
      }

      .header__actions {
        transition: color 0.35s ease-in-out;
      }
    }


    .card-prioritised {
      width: rem-calc(24);
      height: rem-calc(24);
      margin-right: rem-calc(10);
      margin-bottom: 2px;
    }
  }
}

// .disable-scrollbars {
//   scrollbar-width: none; /* Firefox */
//   -ms-overflow-style: none;  /* IE 10+ */
//   &::-webkit-scrollbar {
//     width: 0px;
//     background: transparent; /* Chrome/Safari/Webkit */
//   }
// }
