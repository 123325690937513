.product-cards {

  .whats-covered {
    border-top: rem-calc(1) solid #D8D8D8;
    padding-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    > p.label {
      margin-left: 0;
    }

    .more-info {
      cursor: pointer;
      font-size: rem-calc(14);
    }

    .covered-components-list {
      ul {
        padding-left: 0;
      }
    }
  }

  .card {
    margin-bottom: 2rem;
    max-width: rem-calc(380);
    border: 2px solid #fff;
    margin-right: rem-calc(30);

    @include media-breakpoint-only(lg) {
      margin-bottom: 0;
    }

    @media(max-width: 1400px) {
      margin-right: 0.875rem; //Three columns next to each other need margin for smaller screens
    }

    &.most-popular {
      border: 2px solid $wf-crayola-blue;
    }
  }

  .product-logo {
    margin: 0.625rem auto 1.25rem;
  }

  .button.primary {
    margin-top: 1.625rem;
  }

  .modal-link {
    padding-top: 1.5rem;
  }
}

.covered-components-list ul {
  margin-left: 0;
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  li {
    list-style: none;
    line-height: 150%;
    margin-bottom: 0.35rem;

    img {
      padding-bottom: rem-calc(5);
      margin-right: rem-calc(5);
    }
  }
}

.most-popular {
  border: 2px solid $wf-crayola-blue;
  margin-top: 0;
  overflow: visible;

  @include media-breakpoint-only(lg){
    margin-top: 0;
  }

  .most-popular-tag {
    top: -1.7rem;
    font-weight: 600;
    font-size: 0.875rem;
    background: $wf-crayola-blue;
    color: #fff;
    padding: 0.1rem 0.625rem 0.2rem;
    border-radius: 3px 3px 0 0;
  }
}

.our-plans .product-cards {
  .button {
    margin-bottom: 1.8rem;
  }
}

.product-tile-snapshot {
  h5 {
    margin-bottom: 1.8rem;
  }

  span.uppercase,
  span.price,
  span.price.annual {
    color: $black;
  }

  span.uppercase {
    font-weight: 600;
  }

  span.price,
  span.price.annual {
    font-weight: 800;
    font-size: 1.875rem;
  }
}

.product-card-mini {
  background: white;

  span.price {
    font-weight: 800;
    font-size: 1.875rem;
    color: $black;
  }

  .position-absolute {
    bottom: 0;
    right: 0;
  }

  .button {
    margin-bottom: rem-calc(12);
  }

  .price {
    line-height: 100%;
  }
}

.plan-icons {
  img.right,
  img.left {
    width: rem-calc(140);
    top: -7rem;

    @include media-breakpoint-only(md) {
      width: rem-calc(160);
      top: -8rem;
    }

    @include media-breakpoint-only(lg){
      width: rem-calc(180);
      top: -9.6rem;
    }
  }

  img.left {
    left: 1rem;
  }

  img.right {
    right: 1rem;
  }
}

// Toggle
.products {
  .monthly-summary {
    display: block;
    &.hide {
      display: none;
    }
  }
}

.product-tile-snapshot p.plan-description {
  padding: 0 rem-calc(30);

  @include media-breakpoint-only(lg) {
    min-height: rem-calc(72);
  }
}

.product-tile-snapshot p.no-price {
  font-style: italic;
  margin-bottom: 80px;
}

.card {
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(45,44,49,.1);
  border-radius: 4px;
  padding: 1.25rem;
}

.toggle-options {
  margin-bottom: rem-calc(25);
}