.dealer-details {
  padding: 1.875rem; // 30px
}

.dealer-dashboard {
  padding: 1.875rem; // 30px
  margin-top: 1.875rem;
  max-width: 980px;

  &.fullscreen {
    max-width: inherit;
  }

  .dashboard-year {
    display: "inline-block";
    width: 100px;
  }

  button {
    padding: 10px;
  }

  table {
    label {
      margin-bottom: 0;
    }
    td {
      font-size: 0.875rem !important;
      min-width: 100px;
    }
    tr td:first-of-type {
      min-width: 150px;
    }
    tr td:last-of-type {
      min-width: 130px;
    }
    p {
      margin: 0;
    }
    tr.variance-row {
      border-bottom: 2px solid lightgray;
    }
  }
}

.dealer-rev-counter {
  margin-top: 1.875rem;
}