.omnibox {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  height: auto;
  max-width: 35rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.125rem;
  @extend .form-control;

  &.focus {
    @extend .form-control, :focus;

    .option-box.filters-open {
      .options {
        .option:not(.option--open) {
          display: none;
        }
      }
    }
  }

  .search-box {
    flex: 1;
    position: relative;

    .form-control {
      width: 100%;
      max-height: 2rem;
      min-width: 10rem;
      outline: none;
      border: none;
    }
  }

  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    //min-height: 2rem;
    .filter {
      margin-right: 0.125rem;

      .filter-label {
        position: relative;
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 1.875rem;
        align-items: center;
        box-sizing: border-box;
        background-color: #F5F5F5;
        font-weight: 400;
        margin-right: 0.125rem;
        border-radius: 4px 0 0 4px;

        &:last-of-type {
          margin-right: 0px;
        }
      }

      .filter-value {
        border: 1px solid rgba(45, 44, 49, 0.2);
        border-radius: 4px;
        height: 100%;
        display: inline-flex;
        align-items: center;
      }
    }

    .remove {
      color: $gray-500;
      cursor: pointer;
      font-size: 1.2em;
      line-height: 0em;
      font-weight: bold;
      margin-left: 0.25rem;

      transform: translateY(-0.05rem);
    }
  }

  .filter-icon {
    font-size: 1em;
    margin-right: 0.325rem;
    font-weight: 400;
  }

  .option-box {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + 0.5rem));
    color: rgba(45, 44, 49, 0.7);
    background: #FFFFFF;
    border-radius: 0.25em;
    border: 1px solid rgba(45, 44, 49, 0.2);
    box-shadow: 0 4px 20px 0 rgba(45, 44, 49, 0.1);
    font-size: 1em;
    min-width: 12.5rem;
    z-index: 999;

    &.filters-open {
      background: transparent;
      border: none;
      box-shadow: none;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      overflow: hidden;

      i.arrow {
        font-size: 0.7em;
        font-weight: bold;
      }

      .option-filters {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-width: 15rem;
        overflow-y: scroll;
        background: #FFF;
        border-radius: 0.25em;
        transform: translateX(100%);
        transition: transform 0.1s linear;
        max-height: 17.5rem;
        display: none;

        .filter {
          display: flex;
          align-items: center;

          .filter-avatar {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            margin-right: 0.625rem;
          }

          .color-blob {
            width: 1rem;
            height: 1rem;
            margin-right: 0.625rem;
            border-radius: 50%;
            border: 1px solid #FFF;
          }

          &:hover {
            background: #F5F5F5;
          }

          &.active {
            background: #1673FF;
            color: #FFF;

            &:hover {
              background: darken(#1673FF, 5%);
            }
          }
        }

        &.active {
          display: block;
          transform: translateX(0);
          background: #FFFFFF;
          border-radius: 0.25em;
          border: 1px solid rgba(45, 44, 49, 0.2);
          box-shadow: 0 4px 20px 0 rgba(45, 44, 49, 0.1);
        }
      }
    }

    strong {
      font-size: 0.875em;
    }

    .search-text {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .bg-grey {
      background-color: #F5F5F5;
    }
  }

  .clear {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    right: 1rem;
    font-size: 1.25em;
    line-height: 1;
    font-weight: bold;
    cursor: pointer;
  }

  .spinner-border {
    position: absolute;
    top: 25%;
    right: 1rem;
  }
}
