.gauge-container {
  width: 100px;
  height: 100px;
  border: 3px solid #7078A0;
  border-radius: 50%;
  position: relative;
}

.gauge-container .center-point {
  width: 15px;
  height: 15px;
  border: 2px solid #111E60;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 2;
  transition: all 0.4s;
}
.gauge-container .center-point::after {
  content: '';
  width: 15px;
  height: 15px;
  background-color: rgba(17, 30, 96, 0.3);
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
}

.gauge-container .arrow {
  width: 70px;
  height: 5px;
  background: linear-gradient(to right, #111E60 53%, transparent 53%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 1;
  transition: all 0.4s;
}
.gauge-container .arrow::after {
  content: '';
  width: 35px;
  height: 5px;
  background-color: rgba(17, 30, 96, 0.3);
  position: absolute;
  left: 4px;
  top: 4px;
}

.gauge-container .bottom-rectangle {
  width: 40px;
  height: 12px;
  border: 2px solid #111E60;
  position: absolute;
  left: 27px;
  top: 66px;
  z-index: 2;
}
.gauge-container .bottom-rectangle::after {
  width: 37px;
  height: 10px;
  background-color: rgba(17, 30, 96, 0.3);
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
}

.gauge-scale {
  width: 2px;
  height: 94px;
  background: linear-gradient(0deg, rgba(17, 30, 96, 0.6) 8%, transparent 8%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
}
.gauge-scale-1 {
  transform: translate(-50%, -50%) rotate(60deg);
}
.gauge-scale-2 {
  transform: translate(-50%, -50%) rotate(80deg);
}
.gauge-scale-3 {
  transform: translate(-50%, -50%) rotate(100deg);
}
.gauge-scale-4 {
  transform: translate(-50%, -50%) rotate(120deg);
}
.gauge-scale-5 {
  transform: translate(-50%, -50%) rotate(140deg);
}
.gauge-scale-6 {
  transform: translate(-50%, -50%) rotate(160deg);
}
.gauge-scale-7 {
  transform: translate(-50%, -50%) rotate(180deg);
}
.gauge-scale-8 {
  transform: translate(-50%, -50%) rotate(200deg);
}
.gauge-scale-9 {
  transform: translate(-50%, -50%) rotate(220deg);
}
.gauge-scale-10 {
  transform: translate(-50%, -50%) rotate(240deg);
}
.gauge-scale-11 {
  transform: translate(-50%, -50%) rotate(260deg);
}
.gauge-scale-12 {
  transform: translate(-50%, -50%) rotate(280deg);
}
.gauge-scale-13 {
  transform: translate(-50%, -50%) rotate(300deg);
}


.gauge-container .slice-container {
  width: 94.5px;
  height: 94.5px;
  padding: 0;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  list-style: none;
}

.gauge-container .slice {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
}

.gauge-container li {
  overflow: hidden;
  position: absolute;
  top: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  transform-origin: 0% 100%;
}

.gauge-container li:first-child {
  transform: rotate(-120deg) skewY(170deg);
}

.gauge-container li:nth-child(2) {
  transform: rotate(-40deg) skewY(170deg);
}

.gauge-container li:nth-child(3) {
  transform: rotate(40deg) skewY(170deg);
}

.gauge-container li:first-child .slice {
  background: #DE8979;
}

.gauge-container li:nth-child(2) .slice {
  background: #E9D28A;
}

.gauge-container li:nth-child(3) .slice {
  background: #96CE84;
}
