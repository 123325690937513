.form-group + .form-group:last-child:not(.form-inline) {
  margin-bottom: 0;
}

.form-group {
  label {
    font-size: 14px;
    color: $gray-800;
    font-weight: 600;
  }
}

.col-form-label {
  font-weight: 600;
  padding-bottom: $label-margin-bottom;
}

.input-group-text {
  color: rgba(45, 44, 49, 0.4);
}

.form-control {
  background: #fff;

  &.is-invalid {
    background: url('../images/icons/wf-error-exclamation.svg') no-repeat calc(100% - #{$input-padding-x}) center;
  }

  &.is-valid {
    background-color: #fff !important;
    background: #fff url('../images/icons/wf-success-tick.svg') no-repeat calc(100% - #{$input-padding-x}) center;
  }

  &.square {
    width: $input-height;
  }
}

.form-control::-webkit-input-placeholder {
  color: lighten($gray-800, 50%);
}

.form-control:-moz-placeholder {
  color: lighten($gray-800, 50%);
}

.form-control::-moz-placeholder {
  color: lighten($gray-800, 50%);
}

.form-control:-ms-input-placeholder {
  color: lighten($gray-800, 50%);
}

.form-control::-ms-input-placeholder {
  color: lighten($gray-800, 50%);
}

.form-control-sm {
  &.is-invalid {
    background: url('../images/icons/wf-error-exclamation.svg') no-repeat calc(100% - #{$input-padding-x-sm}) center;
  }

  &.is-valid {
    background: url('../images/icons/wf-success-tick.svg') no-repeat calc(100% - #{$input-padding-x-sm}) center;
  }

  &.square {
    width: $input-height-sm;
  }
}

.form-control-lg {
  &.is-invalid {
    background: url('../images/icons/wf-error-exclamation.svg') no-repeat calc(100% - #{$input-padding-x-lg}) center;
  }

  &.is-valid {
    background: url('../images/icons/wf-success-tick.svg') no-repeat calc(100% - #{$input-padding-x-lg}) center;
  }

  &.square {
    width: $input-height-lg;
  }
}

.custom-control-label {
  height: 32px;
  padding-top: 1px;
}

.swatch {
  @extend .rounded-circle;
  height: calc((#{$input-height-inner} + #{$input-height-border}) * 0.8);
  width: calc((#{$input-height-inner} + #{$input-height-border}) * 0.8);
}

.form-control,
.custom-select {
  .was-validated &:invalid,
  &.is-invalid {
    &:focus {
      border-color: $form-feedback-invalid-focus-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-focus-color, 0.25);
    }
  }

  &:disabled {
    background-color: rgba(45, 44, 49, 0.05);
    border: 1px solid rgba(45, 44, 49, 0.2);
    color: rgba(45, 44, 49, 0.4);
  }
}

.form-control,
.custom-select {
  .was-validated &:valid,
  &.is-valid {
    &:focus {
      border-color: $form-feedback-valid-focus-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-focus-color, 0.25);
    }
  }
}

.custom-select-sm {
  background-position: right $custom-select-padding-x-sm center;
  line-height: $input-line-height-sm;
}

.custom-select-lg {
  background-position: right $custom-select-padding-x-lg center;
}

.custom-textarea {
  min-height: 7.5rem;
}

.form-heading-xs {
  font-size: 14px;
  font-size: 0.875em;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.input-group-text {
  font-size: 1.25em;
  color: rgba(45, 44, 49, 0.4);
}

.wf-datepicker-input {
  padding-right: 3rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-show-container {
  position: relative;
}

.show-btn {
  color: rgba(22, 115, 255, 1);
  position: absolute;
  right: 2.18rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.875em;
}

.disabled {
  color: rgba(45, 44, 49, 0.3);
  pointer-events: none;
}

ul.validation {
  list-style-position: inside;

  li {
    color: #f7c500;

    &.is-valid {
      color: #70b725;
    }

    span {
      color: #666;
    }
  }
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: auto;
}

.form-user-group-create {
  margin-bottom: rem-calc(60);
}

.form-steps {
  a {
    text-decoration: none;
    outline: 0;

    &.active {
      color: theme-color('secondary');
    }
  }
}

h5.form-step {
  cursor: pointer;
}

h5.form-step.current h5.form-step.current ~ * {
  color: theme-color('secondary');
}

.form-steps .form-step:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 40px;
  position: absolute;
  background: #ccc;
  top: 11px;
  right: -50px;
}

.form-step {
  position: relative;
  margin-bottom: 0;
  color: theme-color(green);
  margin-right: 60px;
}


.form-step.current ~ * {
  color: rgba(45,44,49,0.4);
}

.pricing-different {
  border-color: #1673FF;
  background-color: #F3F8FF !important;
}

.react-select__control--is-disabled  .react-select__value-container > div {
  background-color: unset;
}

.price-rule-coverlevel, .price-rule-durations {
  max-width: 340px;
  & > div {
    & > div {
      display: inline-block;
    }
  }
}

/** Fix for button on lookup for vehicle details form **/
.vehicle-details-form {
  input[name=vrm] {
    & ~ .input-group-append {
      z-index: 1 !important;
    }
  }
}