.notification-container {
  top: 1.875rem !important;
  right: 1.875rem !important;
  width: 18.75rem !important;
}

div.notification-wrapper {
  overflow: visible;
}

div.notification {
  @extend .alert;
  @extend .d-flex;
  @extend .shadow-light;
  line-height: 1.15;
  margin: 0 0 1.875rem;
  padding: 0.875rem;

  &-success {
    @include alert-variant($white, $input-border-color, $wf-charleston);
    &:before {
      content: '';
      width: 25px;
      height: 25px;
      background: url('../images/icons/wf-icon-success.png') 0 0 no-repeat;
      background-size: contain;
    }

    .notification-message {
      margin-left: 35px;
    }
  }

  &-error {
    @include alert-variant(theme-color-level('danger', $alert-bg-level), theme-color-level('danger', $alert-border-level), theme-color-level('danger', $alert-color-level));
    &:before {
      content: none;
    }
  }
}

.notification-title {
  font-family: $font-family-sans-serif;
  font-size: 1.5em;
  margin: -0.1875rem 0.4375rem -0.1875rem -0.1875rem;
}

.notification-message {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  font-size: 14px;
  @extend .flex-grow-1
}

///////////////////////////////////////
//    Notification centre
///////////////////////////////////////

.notifications-centre {
  position: absolute;
  width: 250px;
  height: calc(100% - 54px);
  right: 0;
  top: 54px;
  background: #fff;
  z-index: 99;

  .notifications-group {

    &__title {
      background: #F5F5F5;
      border-left: 4px solid $wf-royal-blue;
      padding: rem-calc(13) rem-calc(16);
      font-size: 14px;
      color: #2D2C31;
    }
  }

  .notification-item {
    padding: 14px 20px 10px 14px;
    border-left: 4px solid #F5F5F5;
    margin-bottom: 1px;
    margin-top: 1px;

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

  }
  .notification-item--unread {
    border-left: 4px solid $wf-golden-poppy;
  }
}